body {
    overflow: hidden;
    background-image: linear-gradient(to right, #aed9e0, #86c8d1, #5ab9c4, #2ca9b8, #0099ad); /* Linearer Verlauf von hellen Blautönen */

  }
.container {
    display:flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .content {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 0.75rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    text-align: center;
  }
  
  .title {
    font-size: 2.25rem;
    font-weight: 700;
    color: #1F2937;
    margin-bottom: 1rem;
  }
  
  .text {
    font-size: 1.125rem;
    color: #4B5563;
  }
  .text-logo{
    padding-top: 0.7rem;

    font-size: 1rem;
    color: rgb(180, 180, 180)
  }